import { render, staticRenderFns } from "./users-card.vue?vue&type=template&id=6661ed46&scoped=true&"
import script from "./users-card.vue?vue&type=script&lang=js&"
export * from "./users-card.vue?vue&type=script&lang=js&"
import style0 from "./users-card.vue?vue&type=style&index=0&id=6661ed46&prod&lang=scss&scoped=true&"
import style1 from "./users-card.vue?vue&type=style&index=1&id=6661ed46&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6661ed46",
  null
  
)

export default component.exports