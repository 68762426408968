<script>
export default {
  name: "ListGroup",
  props: {
    linked: {
      type: Boolean,
      default: false
    },
    stripped: {
      type: Boolean,
      default: false
    },
    reverseStrip: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      linked: this.linked
    };
  },
  render(h) {
    if (
      this.$slots.default?.every?.((vnode) =>
        vnode.tag.endsWith("ListGroupItem")
      )
    )
      return h(
        this.linked ? "div" : "ul",
        {
          class: [
            "list-group"
          ]
        },
        this.$slots.default
      );
    else return h(this.linked ? "div" : "ul");
  }
};
</script>

<style scoped>
.skin-dark .list-group-item a {
  color: #fff;
}
.skin-dark .list-group-item.active {
  color: #fff;
  background-color: var(--skin-dark-darker);
}
</style>
