<template>
  <div
    :class="[
      'privileges-display row',
      featureFlag,
      status,
      { [`was-${lastStatus}`]: lastStatus, 'entity-selected': selectedEntity }
    ]"
  >
    <v-tour
      :name="featureFlag"
      :steps="onboarding.steps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
    >
      <template v-slot="tour">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :class="{ 'privileges-feature-question': tour.isLast }"
          :step="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :finish="tour.finish"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :enabled-buttons="tour.enabledButtons"
          :highlight="tour.highlight"
          :debug="tour.debug"
        >
          <template #content v-if="tour.isLast">
            <div class="v-step__content">
              <span v-html="tour.steps[tour.currentStep].content"></span>
              <Checkbox
                :checked="featureConfig.enabled"
                @change="featureConfig = $event"
                id="set-feature-as-default"
              >
                {{ $t("yes") }}
              </Checkbox>
            </div>
          </template>
          <template #actions>
            <div class="v-step__buttons">
              <BaseButton
                @click.prevent="tour.skip"
                v-if="!tour.isLast && tour.enabledButtons.buttonSkip"
                class="v-step__button-skip"
                type="default"
                small
              >
                {{ tour.labels.buttonSkip }}
              </BaseButton>
              <BaseButton
                @click.prevent="tour.previousStep"
                v-if="
                  !tour.isFirst &&
                    !tour.isLast &&
                    tour.enabledButtons.buttonPrevious
                "
                class="v-step__button-previous"
                type="primary"
                small
              >
                {{ tour.labels.buttonPrevious }}
              </BaseButton>
              <BaseButton
                @click.prevent="tour.nextStep"
                v-if="!tour.isLast && tour.enabledButtons.buttonNext"
                class="v-step__button-next"
                type="primary"
                small
              >
                {{ tour.labels.buttonNext }}
              </BaseButton>
              <BaseButton
                @click.prevent="tour.finish"
                v-if="tour.isLast && tour.enabledButtons.buttonStop"
                class="v-step__button-stop"
                type="info"
                small
              >
                {{ tour.labels.buttonStop }}
              </BaseButton>
            </div>
          </template>
        </v-step>
      </template>
    </v-tour>
    <span class="selected-entity" ref="selectedEntityEl">
      {{ selectedEntity ? selectedEntity.name : "" }}
    </span>
    <transition name="slide-fade">
      <div
        class="info-text col-md-4 col-md-offset-7"
        v-if="status == 'done' && !isMobile"
      >
        <i class="glyphicon glyphicon-list-alt"></i>
        {{
          $tc(
            "stateless_access_control_text",
            ["permission-group", "process-area"].indexOf(selectedEntity.type) +
              1,
            {
              hierarchy: $tc(
                selectedEntity.type.replace("-", "_")
              ).toLowerCase()
            }
          )
        }}
      </div>
    </transition>
    <!-- visible when viewport < 1200px -->
    <HierarchyToggle
      class="section hidden-lg"
      :value="hierarchy"
      @input="setHierarchy($event)"
      key="hierarchy"
      v-if="
        !status && (isMobile ? !(selectedGroup || selectedProcessArea) : true)
      "
    />
    <transition-group
      class="cards-container"
      tag="div"
      :name="transition"
      :css="transitionEnabled"
      @before-enter="adjustTransition($event, 'beforeEnter')"
      @before-leave="adjustTransition($event, 'beforeLeave')"
      @enter="test($event, 'enter')"
      @leave="test($event, 'leave')"
      @after-enter="afterEnterTransition"
      @after-leave="adjustTransition($event, 'afterLeave')"
      v-if="!loading"
    >
      <!-- visible when viewport >= 1200px -->
      <HierarchyToggle
        class="section visible-lg-flex"
        :value="hierarchy"
        @input="setHierarchy($event)"
        key="hierarchy"
        v-if="!status"
      />
      <!-- Groups list -->
      <FilterableList
        class="section hierarchy-list col-md-6"
        key="select-groups"
        :title="$t('select_a_group')"
        :selected="selectedGroup"
        :list="groups"
        :searchFilter="filterGroupByUser"
        :placeholder="$t('search_group_by_fields')"
        :useQuery="true"
        @select="selectGroup"
        v-if="
          hierarchy == 'group' && !status && (isMobile ? !selectedGroup : true)
        "
      />
      <!-- Process Areas list -->
      <FilterableList
        class="section hierarchy-list col-md-6"
        key="select-process-area"
        :title="$t('select_a_process_area')"
        :selected="selectedProcessArea"
        :list="processAreas"
        :searchFilter="filterProcessAreaByUser"
        :placeholder="$t('search_process_area_by_fields')"
        :useQuery="true"
        @select="selectProcessArea"
        v-if="
          hierarchy == 'process-area' &&
            !status &&
            (isMobile ? !selectedProcessArea : true)
        "
      />
      <!-- Users in selected group or process area -->
      <UsersCard
        class="section col-md-6"
        :type="!status || status == 'done' ? 'primary' : 'default'"
        :group="selectedGroup"
        :processArea="selectedProcessArea"
        :selected="currentSelectedUsers"
        :singleSelection="usersSingleSelection"
        key="users"
        :loading="isLoading && action == 'removing'"
        :allowRemove="true"
        :title="usersListingTitle"
        @select="selectUsers"
        @remove="removeUsers"
        @shortcut:processArea="addProcessArea"
        @shortcut:group="addGroup"
        v-if="
          (selectedGroup || selectedProcessArea) &&
            (isMobile
              ? !status || status == 'done'
              : status != 'adding-hierarchy-entity')
        "
      >
        <template #footer>
          <div class="back">
            <BaseButton
              type="default"
              @click="back"
              :small="isMobile"
              v-if="status || isMobile"
            >
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
          </div>
          <div class="add">
            <BaseButton
              type="primary"
              @click="addUser"
              :active="status == 'adding'"
              :disabled="status == 'adding'"
              :small="isMobile"
            >
              <i class="fa fa-user-plus"></i>
              {{ $t("add") + " " + $tc("user").toLowerCase() }}
            </BaseButton
            >
          </div>
          <div class="spacing text-right">
            <transition name="slide-up">
              <BaseButton
                type="primary"
                @click="editSelectedUsers"
                :small="isMobile"
                v-if="status != 'editing' && selectedUsers.length"
              >
                <i class="fa fa-edit"></i>
                <span> {{ $t("edit") }}</span>
              </BaseButton>
            </transition>
          </div>
        </template>
      </UsersCard>
      <!-- List of Process Areas for selected users -->
      <ProcessAreasCard
        class="section hierarchy-entity-card col-md-6"
        :users="selectedUsers"
        :group="selectedGroup"
        :loading="isLoading && action == 'saving'"
        :readOnly="selectedUsers.length == 0"
        :showSubtitleTerm="true"
        :footerSpacing="!isMobile"
        @save="(processAreas, done) => saveUsers({ processAreas }, done)"
        @dirty="hasUnsavedChanges = $event"
        key="process-areas"
        v-if="status == 'editing' && selectedGroup"
      >
        <template #footer v-if="isMobile">
          <div class="back">
            <BaseButton type="default" @click="setStatus('list', true)" small>
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
          </div>
        </template>
      </ProcessAreasCard>
      <!-- List of Groups for selected users -->
      <GroupsCard
        class="section hierarchy-entity-card col-md-6"
        :users="selectedUsers"
        :processArea="selectedProcessArea"
        :loading="isLoading && action == 'saving'"
        :readOnly="selectedUsers.length == 0"
        :showSubtitleTerm="true"
        :footerSpacing="!isMobile"
        @save="(groups, done) => saveUsers({ groups }, done)"
        @dirty="hasUnsavedChanges = $event"
        key="groups"
        v-if="status == 'editing' && selectedProcessArea"
      >
        <template #footer v-if="isMobile">
          <div class="back">
            <BaseButton type="default" @click="setStatus('list', true)" small>
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
          </div>
        </template>
      </GroupsCard>
      <!-- Users to be added -->
      <UsersCard
        class="section add-user col-md-6"
        :singleSelection="false"
        :showPrivileges="false"
        :group="selectedGroup"
        :processArea="selectedProcessArea"
        filter="exclude,owner"
        :title="
          $t(`select_adding_users_to_${selectedEntity.type.replace('-', '_')}`)
        "
        :emptyListText="
          $t(
            `existing_users_already_in_${selectedEntity.type.replace('-', '_')}`
          )
        "
        @select="addingUsers = $event"
        key="adding-users"
        v-if="
          status &&
            (isMobile ? status == 'adding' : status.startsWith('adding'))
        "
      >
        <template
          #footer
          v-if="
            addingUsers.length > 0 ||
              status == 'adding-hierarchy-entity' ||
              isMobile
          "
        >
          <div :class="isMobile ? 'flex-spaced' : 'text-center'">
            <BaseButton
              type="default"
              @click="setStatus('list')"
              style="margin-right: 10px"
              :small="isMobile"
              v-if="isMobile"
            >
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
            <BaseButton
              type="primary"
              :disabled="addingUsers.length == 0"
              :small="isMobile"
              @click="addUsersHierarchyEntity"
            >
              {{
                isMobile
                  ? $tc(selectedEntity.type.replace("-", "_"), 2)
                  : $t(`select_adding_${entityToAdd}s`)
              }}
              <i class="fa fa-long-arrow-right" style="margin-left: 1rem"></i
            ></BaseButton>
          </div>
        </template>
      </UsersCard>
      <!-- Process Areas to associate with adding users-->
      <ProcessAreasCard
        class="section hierarchy-entity-card add-user col-md-6"
        :localDirty="false"
        :dirty="isAddingDirty"
        :title="$t('select_adding_process_areas')"
        :hint="addingUsers.length == 0 ? $t('select_user_to_continue') : ''"
        :loading="isLoading && action == 'saving'"
        @select="addingHierarchyEntity = $event"
        @save="saveUsers({ processAreas: addingHierarchyEntity }, addingUsers)"
        key="adding-process-areas"
        v-if="status == 'adding-hierarchy-entity' && selectedGroup"
      >
        >
        <template #footer>
          <div class="back">
            <BaseButton type="default" :small="isMobile" @click="backAdding">
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
          </div>
        </template>
      </ProcessAreasCard>
      <!-- Groups to associate with adding users -->
      <GroupsCard
        class="section hierarchy-entity-card add-user col-md-6"
        :localDirty="false"
        :dirty="isAddingDirty"
        :title="$t('select_adding_groups')"
        :hint="addingUsers.length == 0 ? $t('select_user_to_continue') : ''"
        :loading="isLoading && action == 'saving'"
        @select="addingHierarchyEntity = $event"
        @save="saveUsers({ groups: addingHierarchyEntity }, addingUsers)"
        key="adding-groups"
        v-if="status == 'adding-hierarchy-entity' && selectedProcessArea"
      >
        >
        <template #footer>
          <div class="back">
            <BaseButton type="default" :small="isMobile" @click="backAdding">
              <i class="fa fa-long-arrow-left"></i> {{ $t("back") }}</BaseButton
            >
          </div>
        </template>
      </GroupsCard>
      <!-- Process Areas to associate with all users -->
      <ProcessAreasCard
        class="section hierarchy-entity-card add-entity col-md-6"
        type="info"
        :title="$t('associate_process_areas_with_users')"
        :loading="isLoading && action == 'saving'"
        :tooltip="$t('titles.select_process_areas_for_all_users')"
        @dirty="hasUnsavedChanges = $event"
        @save="
          saveUsers(
            { processAreas: $event },
            registeredUsers.filter((user) => isUserInGroup(user)),
            false
          )
        "
        key="editing-process-areas"
        v-if="status == 'editing-process-area' && selectedGroup"
      >
      </ProcessAreasCard>
      <!-- Groups to associate with all users -->
      <GroupsCard
        class="section hierarchy-entity-card add-entity col-md-6"
        type="info"
        :title="$t('associate_groups_with_users')"
        :loading="isLoading && action == 'saving'"
        :tooltip="$t('titles.select_groups_for_all_users')"
        @dirty="hasUnsavedChanges = $event"
        @save="
          saveUsers(
            { groups: $event },
            registeredUsers.filter((user) => isUserInProcessArea(user)),
            false
          )
        "
        key="editing-groups"
        v-if="status == 'editing-group' && selectedProcessArea"
      >
      </GroupsCard>
    </transition-group>
  </div>
</template>

<script>
import FilterableList from "@/components/access-control/filterable-list";
import UsersCard from "@/components/access-control/users-card";
import ProcessAreasCard from "@/components/access-control/process-areas-card";
import GroupsCard from "@/components/access-control/groups-card";
import BaseButton from "@/components/base/buttons/base-button";
import Checkbox from "@/components/base/checkbox";
import HierarchyToggle from "@/components/access-control/hierarchy-toggle";
import AlertDiscardChanges from "./alert-discard-changes";
import MixinMobile from "@/project/mixin-mobile";

import { mapActions, mapGetters } from "vuex";
import { whichTransitionEvent } from "@/utils";
import VueRouter from "vue-router";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  name: "PrivilegesDisplay",
  mixins: [MixinMobile],
  components: {
    FilterableList,
    UsersCard,
    ProcessAreasCard,
    GroupsCard,
    BaseButton,
    Checkbox,
    HierarchyToggle
  },
  props: {
    groupId: {
      type: Number,
      required: false
    },
    processAreaId: {
      type: Number,
      required: false
    },
    users: {
      type: [Number, String],
      required: false
    },
    state: {
      type: String,
      required: false,
      validator: (val) =>
        [
          "adding",
          "editing",
          "editing-process-area",
          "editing-group",
          "list",
          "done"
        ].includes(val)
    }
  },
  data() {
    return {
      loading: false,
      transitionEnabled: true,
      selectedGroup: null,
      selectedProcessArea: null,
      status: null,
      lastStatus: null,
      action: null,
      hasUnsavedChanges: false,
      sleepTime: 150,
      transition: "section",
      selectedUsers: [],
      addingUsers: [],
      addingHierarchyEntity: [],
      currentSelectedUsers: [], // for updating current selected list
      usersLoadedQueue: [],
      groupsLoadedQueue: [],
      processAreasLoadedQueue: [],
      afterEnterTransitionQueue: [],
      usersSingleSelection: true,
      baseRoute: "/dashboard/access-control/privileges",
      mobileThreshold: 992,
      hierarchy: "",
      usersListingTitle: "",
      featureFlag: "new-access-control",
      tourOptions: {
        highlight: true,
        labels: {
          buttonSkip: this.$t("skip_tour"),
          buttonPrevious: this.$t("previous"),
          buttonNext: this.$t("next"),
          buttonStop: this.$t("finish")
        }
      },
      tourCallbacks: {
        onSkip: () => this.updateFeatureState(true)
      }
    };
  },
  computed: {
    isAddingDirty() {
      return (
        this.addingUsers.length > 0 && this.addingHierarchyEntity.length > 0
      );
    },
    selectedEntity() {
      if (this.selectedGroup)
        return { ...this.selectedGroup, type: "permission-group" };
      if (this.selectedProcessArea)
        return { ...this.selectedProcessArea, type: "process-area" };
      return null;
    },
    entityToAdd() {
      let entitiesReverse = {
        "permission-group": "process_area",
        "process-area": "group"
      };
      return this.selectedEntity
        ? entitiesReverse[this.selectedEntity.type]
        : "";
    },
    onboarding() {
      return {
        steps: [
          {
            target: "#users-panel .nav-tabs li.active",
            header: {
              title: this.$t(`onboarding.${this.featureFlag}.title`)
            },
            content: this.$t(`onboarding.${this.featureFlag}.step1`)
          },
          {
            target: ".hierarchy-list",
            content: this.$t(`onboarding.${this.featureFlag}.step2`)
          },
          {
            before: () =>
              new Promise((resolve) => {
                if (!this.selectedGroup) {
                  this.selectedGroup = this.groups.find(
                    (group) => group.is_default
                  );
                  this.afterEnterTransitionQueue.push(() => resolve());
                  this.$router.push(
                    `${this.baseRoute}/permission-group/${this.selectedGroup.id}`
                  );
                } else {
                  resolve();
                }
              }),
            target: ".users-card .card",
            content: this.$t(`onboarding.${this.featureFlag}.step3`)
          },
          {
            target: ".add .btn.btn-success",
            content: this.$t(`onboarding.${this.featureFlag}.step4`)
          },
          {
            content: this.$t(`onboarding.${this.featureFlag}.step5`, {
              manual: this.featureConfig.documentationURL
            }),
            params: {
              highlight: false,
              stopOnTargetNotFound: false
            }
          }
        ]
      };
    },
    featureConfig: {
      get() {
        return this.$featureToggle.featureConfig[this.featureFlag];
      },
      set(val) {
        this.updateFeatureState(val);
      }
    },
    ...mapGetters("user", {
      isLoading: "isLoading",
      contract_id: "contract_id",
      registeredUsers: "users"
    }),
    ...mapGetters("role", { groups: "roles" }),
    ...mapGetters("processArea", ["processAreas"])
  },
  watch: {
    status(status, oldVal) {
      this.lastStatus = oldVal;
      switch (status) {
        case "adding":
          if (this.lastStatus != "adding-hierarchy-entity")
            this.addingUsers = this.addingHierarchyEntity = [];
      }
    },
    addingUsers() {
      if (this.status == "adding") {
        this.hasUnsavedChanges = this.addingUsers.length > 0;
      } else if (this.status == "adding-hierarchy-entity") {
        this.hasUnsavedChanges = this.addingHierarchyEntity.length > 0;
      }
    },
    addingHierarchyEntity() {
      this.hasUnsavedChanges = this.addingHierarchyEntity.length > 0;
    },
    $route: {
      immediate: true,
      handler: "updateInternalState"
    },
    loading(val) {
      this.$emit("loading", val);
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (await this.confirmDiscardChanges()) {
      next();
    } else {
      next(false);
    }
  },
  methods: {
    updateInternalState(to, from) {
      if (!to.path.startsWith(this.baseRoute)) return;

      if (this.state == "done" && !this.selectedEntity) {
        this.$router.push(this.baseRoute);
        return;
      }

      const setSelectedGroup = () => {
        this.selectedGroup =
          this.groups.find((group) => group.id == this.groupId) ??
          this.selectedGroup;
      };
      // set selected group or await fetch to do it
      if (!this.groups.length) {
        this.groupsLoadedQueue.push(setSelectedGroup);
      } else setSelectedGroup();

      const setSelectedProcessArea = () => {
        this.selectedProcessArea =
          this.processAreas.find(
            (processArea) => processArea.id == this.processAreaId
          ) ?? this.selectedProcessArea;
      };
      // set selected process area or await fetch to do it
      if (!this.processAreas.length) {
        this.processAreasLoadedQueue.push(setSelectedProcessArea);
      } else setSelectedProcessArea();

      // set status as state prop
      this.$nextTick(() => {
        this.status = this.state == "list" ? null : this.state ?? this.status;
        if (to.path.replace(/\/$/, "") == this.baseRoute) {
          this.resetState();
        }
      });

      let setSelectedUsers;
      if (this.users) {
        // parse users ids list from prop (coming from URL query)
        let parsedUsers = this.users.split(",").map(Number);
        // if all are valid
        if (parsedUsers.every((user) => typeof user == "number" && user > 0)) {
          setSelectedUsers = () => {
            this.currentSelectedUsers = this.registeredUsers.filter(
              (user) =>
                parsedUsers.some((u) => u == user.id) &&
                (this.selectedGroup
                  ? this.isUserInGroup(user)
                  : this.isUserInProcessArea(user))
            );
            if (this.currentSelectedUsers.length)
              this.selectUsers([...this.currentSelectedUsers]);
            else
              this.$router.push(
                `${this.baseRoute}/${this.selectedEntity.type}/${this.selectedEntity.id}`
              );
          };
        }
      } else {
        setSelectedUsers = () => {
          this.selectedUsers = [];
          this.currentSelectedUsers = [];
        };
      }

      if (setSelectedUsers) {
        // set selected users or await fetch to do it
        if (!this.registeredUsers.length)
          this.usersLoadedQueue.push(setSelectedUsers);
        else setSelectedUsers();
      }
    },
    resetState() {
      return this.$nextTick().then(() => {
        this.restoreUsersListingTitle();
        this.selectedUsers = [];
        this.currentSelectedUsers = [];
        this.selectedGroup = null;
        this.selectedProcessArea = null;
        this.status = null;
      });
    },
    selectGroup(group) {
      // if group is selected while in tour (aka. user selected)
      if (group && this.$tours[this.featureFlag].isRunning) {
        // automatically go to next step after appearance of users card
        // or instantly if card is already visible
        if (!this.selectedGroup) {
          this.selectedGroup = group;
          this.afterEnterTransitionQueue.push(() =>
            this.$tours[this.featureFlag].nextStep()
          );
        } else {
          this.selectedGroup = group;
          this.$tours[this.featureFlag].nextStep();
        }
      } else {
        this.selectedGroup = group;
      }
      this.selectedProcessArea = null;
      this.selectedUsers = [];
      this.currentSelectedUsers = [];
      this.$router.push(
        `${this.baseRoute}/${
          this.selectedGroup ? "permission-group/" + this.selectedGroup.id : ""
        }`
      );
    },
    selectProcessArea(processArea) {
      this.selectedProcessArea = processArea;
      this.selectedGroup = null;
      this.selectedUsers = [];
      this.currentSelectedUsers = [];
      this.$router.push(
        `${this.baseRoute}/${
          this.selectedProcessArea
            ? "process-area/" + this.selectedProcessArea.id
            : ""
        }`
      );
    },
    async selectUsers(users, singleSelection) {
      if (singleSelection && !(await this.confirmDiscardChanges())) {
        this.currentSelectedUsers = [...this.selectedUsers];
        return;
      }

      this.selectedUsers = users;

      if (this.selectedEntity) {
        let newRoute = `${this.baseRoute}/${this.selectedEntity.type}/${
          this.selectedEntity.id
        }/${users.length ? "?users=" + users.map((u) => u.id).join(",") : ""}`;

        if (this.$route.fullPath.replace(/&state=\w*/, "") != newRoute) {
          this.usersSingleSelection = singleSelection;
          // set state as list or editing, depending if it is
          // a single selection or not
          if (users.length)
            newRoute += `&state=${
              singleSelection ? "editing" : this.status ?? "list"
            }`;
          else if (this.status) {
            // if there is no users but there is a status,
            // set status as "done"
            this.transition = "section-hide";
            newRoute += "?state=done";
          }

          this.$router.push(newRoute, async () => {
            await this.$nextTick();
            this.usersSingleSelection = true;
          });
        }
      }
    },
    async editSelectedUsers() {
      if (await this.confirmDiscardChanges()) {
        this.setStatus("editing");
      }
    },
    setStatus(status, reset = false) {
      let newRoute = `${this.baseRoute}${
        this.selectedEntity
          ? `/${this.selectedEntity.type}/${this.selectedEntity.id}`
          : ""
      }/${
        this.selectedUsers.length && !reset
          ? "?users=" + this.selectedUsers.map((u) => u.id).join(",")
          : ""
      }${
        status
          ? (this.selectedUsers.length && !reset ? "&" : "?") +
            "state=" +
            status
          : ""
      }`;
      if (this.$route.fullPath != newRoute) {
        this.$router.push(newRoute).catch(() => {});
      }
    },
    saveUsers(entity, users, remove = true) {
      // receives a "done" callback from ProcessAreas/GroupsCard
      let cb = typeof users == "function" ? users : null;
      users = typeof users == "object" ? users : this.selectedUsers;
      if (!users.length) return;

      let payloads = this.getUsersPayloads(users, entity, remove);
      this.action = "saving";
      this.update({
        payloads,
        users,
        successMessage: "you_have_saved_n_items"
      }).then(() => {
        this.action = null;
        this.hasUnsavedChanges = false;
        // update current selected users with new reference
        this.currentSelectedUsers = this.registeredUsers.filter((user) =>
          this.currentSelectedUsers.some((u) => u.id == user.id)
        );
        this.selectedUsers = [...this.currentSelectedUsers];
        // call done callback from ProcessAreas/GroupsCard
        if (cb) cb();
        // if saved users were just added, set status to "done"
        if (
          ["adding-hierarchy-entity", "editing-process-area"].includes(
            this.status
          ) ||
          this.currentSelectedUsers.every((user) =>
            this.selectedGroup
              ? !this.isUserInGroup(user)
              : !this.isUserInProcessArea(user)
          )
        ) {
          this.transition = "section-hide";
          setTimeout(() => this.setStatus("done", true), this.sleepTime);
        }
      });
    },
    removeUsers(users) {
      let payloads = users.map((user) => {
        user = JSON.parse(JSON.stringify(user));
        return {
          id: user.id,
          etag: user.etag,
          contract_id: this.contract_id,
          associated_permissions: user.process_area
            .filter((processArea) => {
              if (this.selectedGroup) {
                processArea.user_groups = processArea.user_groups.filter(
                  (group) => group.id != this.selectedGroup.id
                );
                return processArea.user_groups.length > 0;
              } else {
                return processArea.id != this.selectedProcessArea.id;
              }
            })
            .map((processArea) => ({
              process_area_id: processArea.id,
              user_groups_ids: processArea.user_groups.map((group) => group.id)
            }))
        };
      });
      this.action = "removing";
      this.update({
        payloads,
        users,
        successMessage: "you_have_deleted_n_items"
      }).then(() => {
        this.action = null;
        // if any deleted user were selected, clear list
        if (
          users.some((user) => this.selectedUsers.some((u) => u.id == user.id))
        ) {
          const allSelectedUsersDeleted =
            this.selectedUsers.every((user) =>
              users.some((u) => u.id == user.id)
            ) && this.selectedUsers.length > 0;
          // filter out deleted users
          if (allSelectedUsersDeleted) {
            this.selectedUsers = [];
          } else {
            this.selectedUsers = this.selectedUsers.filter(
              (user) => !users.some((u) => u.id == user.id)
            );
          }
          this.currentSelectedUsers = [...this.selectedUsers];

          if (this.status == "editing" && allSelectedUsersDeleted) {
            this.transition = "section-hide";
            this.setStatus("done");
          } else if (!this.status || this.status == "list") {
            // force URL update after clearing selected users list
            this.setStatus("list");
          }
        }
      });
    },
    async update({ payloads, users, successMessage }) {
      let responses = await this.updateUsers(payloads);
      let errors = [],
        alertOptions = {
          position: "bottom-right",
          duration: 5000,
          iconPack: "fontawesome",
          keepOnHover: true
        };
      // check for failed operations
      responses.forEach((result, index) => {
        if (result.status == "rejected") {
          errors.push(`${users[index].email}: ${this.$tc(result.reason)}`);
        }
      });

      if (users.length - errors.length > 0) {
        // show success message for saved users
        this.$toasted.show(
          this.$t(successMessage, {
            count: users.length - errors.length
          }),
          {
            type: "success",
            icon: "fa-check",
            ...alertOptions
          }
        );
      }

      // show error message for each failed user save operation
      errors.forEach((error) =>
        this.$toasted.show(error, {
          type: "error",
          icon: "fa-close",
          ...alertOptions
        })
      );
    },
    getUsersPayloads(users, { processAreas, groups }, remove) {
      if (processAreas) {
        return users.map((user) => {
          let payload = {
            id: user.id,
            etag: user.etag,
            contract_id: this.contract_id,
            associated_permissions: []
          };

          user.process_area.forEach((processArea) => {
            // adds each existing process area with groups other than the selected one
            if (
              !remove ||
              processArea.user_groups.some(
                (group) => group.id != this.selectedGroup.id
              )
            ) {
              payload.associated_permissions.push({
                process_area_id: processArea.id,
                user_groups_ids: processArea.user_groups
                  .map((group) => group.id)
                  .filter((id) => (remove ? id != this.selectedGroup.id : true))
              });
            }
          });

          // add saved process areas (or selected group if it already exists)
          processAreas.forEach((processArea) => {
            let existingProcessArea = payload.associated_permissions.find(
              (permission) => permission.process_area_id == processArea.id
            );
            if (!existingProcessArea) {
              payload.associated_permissions.push({
                process_area_id: processArea.id,
                user_groups_ids: [this.selectedGroup.id]
              });
            } else if (
              !existingProcessArea.user_groups_ids.includes(
                this.selectedGroup.id
              )
            ) {
              existingProcessArea.user_groups_ids.push(this.selectedGroup.id);
            }
          });

          return payload;
        });
      } else if (groups) {
        return users.map((user) => {
          let payload = {
            id: user.id,
            etag: user.etag,
            contract_id: this.contract_id,
            associated_permissions: []
          };

          // add all process areas and its groups
          // (overwriting selected one with received groups)
          user.process_area.forEach((processArea) => {
            payload.associated_permissions.push({
              process_area_id: processArea.id,
              user_groups_ids: processArea.user_groups.map((g) => g.id)
            });

            if (processArea.id == this.selectedProcessArea.id) {
              if (remove) {
                payload.associated_permissions.at(
                  -1
                ).user_groups_ids = groups.map((g) => g.id);
              } else {
                payload.associated_permissions
                  .at(-1)
                  .user_groups_ids.push(...groups.map((g) => g.id));
              }
            }
          });

          // add new process area to user with receive groups
          // if it doesn't exist in it yet
          if (
            !user.process_area.some(
              (pa) => pa.id == this.selectedProcessArea.id
            )
          ) {
            payload.associated_permissions.push({
              process_area_id: this.selectedProcessArea.id,
              user_groups_ids: groups.map((g) => g.id)
            });
          }

          return payload;
        });
      }
    },
    async addProcessArea() {
      if (!(await this.confirmDiscardChanges())) return;
      this.selectedUsers = [];
      this.currentSelectedUsers = [];
      this.setStatus("editing-process-area");
    },
    async addGroup() {
      if (!(await this.confirmDiscardChanges())) return;
      this.selectedUsers = [];
      this.currentSelectedUsers = [];
      this.setStatus("editing-group");
    },
    addUser() {
      this.$router
        .push(
          `${this.baseRoute}/${this.selectedEntity.type}/${this.selectedEntity.id}/add-user`
        )
        .catch(() => {});
    },
    addUsersHierarchyEntity() {
      this.usersListingTitle = " ";
      this.status = "adding-hierarchy-entity";
      this.hasUnsavedChanges = false;
    },
    async backAdding() {
      if (!(await this.confirmDiscardChanges())) return;
      this.status = "adding";
      this.hasUnsavedChanges = this.addingUsers.length > 0;
    },
    async back() {
      if (!(await this.confirmDiscardChanges())) return;
      if (this.status && (this.isMobile ? this.status != "done" : true)) {
        this.transition = "section";
        this.setStatus("list", true);
      } else {
        this.selectedGroup = null;
        this.setStatus("list");
      }
    },
    isUserInGroup(user, groupId) {
      return user.process_area.reduce(
        (foundGroup, processArea) =>
          foundGroup ||
          processArea.user_groups.find((group) =>
            groupId ? group.id == groupId : group.id == this.selectedGroup.id
          ),
        null
      );
    },
    isUserInProcessArea(user, processAreaId) {
      return user.process_area.some((pa) =>
        processAreaId
          ? pa.id == processAreaId
          : pa.id == this.selectedProcessArea.id
      );
    },
    allowDiscardChanges(val) {
      if (val == undefined)
        return JSON.parse(
          localStorage.getItem("access_control_allow_discard_changes") ?? false
        );
      else localStorage.setItem("access_control_allow_discard_changes", val);
    },
    async confirmDiscardChanges() {
      if (this.allowDiscardChanges()) return true;
      else if (this.hasUnsavedChanges) {
        const ok = await this.$swal({
          title: this.$t("discard_changes"),
          content: this.discardAlert(),
          icon: "warning",
          buttons: [this.$t("cancel"), this.$t("continue")],
          dangerMode: true
        });
        if (ok) this.hasUnsavedChanges = false;
        return ok;
      }
      return true;
    },
    discardAlert() {
      const alert = new AlertDiscardChanges({
        el: document.createElement("div"),
        i18n: this.$i18n,
        propsData: { ask: this.allowDiscardChanges() }
      });
      alert.$on("input", (val) => this.allowDiscardChanges(val));
      return alert.$el;
    },
    afterEnterTransition(el) {
      this.adjustTransition(el, "afterEnter");
      if (
        this.transition == "section-hide" &&
        !el.matches(".users-card:not(.add-user)")
      )
        this.transition = "section";
      this.afterEnterTransitionQueue.forEach((fn) => fn(el));
      this.afterEnterTransitionQueue = [];
    },
    test(el, name) {
      // console.log({ el, name });
    },
    adjustTransition(el, name) {
      this.test(el, name);
      if (!this.transitionEnabled) return;
      // add card-moving class to slow card's main layout
      // transition (coming and going to group list)
      if (el.classList.contains("card-moving")) {
        el.classList.remove("card-moving");
      } else if (
        ((this.lastStatus == null && this.status) ||
          (this.status == null && this.lastStatus)) &&
        ["add-user", "hierarchy-entity-card"].some((cls) =>
          el.classList.contains(cls)
        )
      ) {
        el.classList.add("card-moving");
      }
    },
    async fetchResources() {
      try {
        this.loading = true;
        await Promise.all([
          this.fetchUsers(),
          this.fetchProcessAreas(),
          this.fetchRoles()
        ]);
        this.groupsLoadedQueue.forEach((fn) => fn());
        this.groupsLoadedQueue = [];

        this.processAreasLoadedQueue.forEach((fn) => fn());
        this.processAreasLoadedQueue = [];

        this.usersLoadedQueue.forEach((fn) => fn());
        this.usersLoadedQueue = [];
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    filterGroupByUser(group, query) {
      let users = this.registeredUsers.filter((user) =>
        user.email.includes(query)
      );
      return users.some((user) => this.isUserInGroup(user, group.id));
    },
    filterProcessAreaByUser(processArea, query) {
      let users = this.registeredUsers.filter((user) =>
        user.email.includes(query)
      );
      return users.some((user) =>
        this.isUserInProcessArea(user, processArea.id)
      );
    },
    setHierarchy(hierarchy) {
      if (hierarchy != this.hierarchy) {
        this.$router
          .push({
            path: this.baseRoute,
            query: this.$route.query
          })
          .catch(() => {});
      }
      this.hierarchy = hierarchy;
      localStorage.setItem("access_control_hierarchy", hierarchy);
    },
    restoreUsersListingTitle() {
      if (this.status != "adding-hierarchy-entity") {
        this.usersListingTitle = "";
      }
    },
    updateFeatureState(enabled) {
      this.$store.dispatch("user/updateFeaturePreference", {
        ...this.featureConfig,
        enabled,
        name: this.featureFlag
      });
    },
    handleFeatureUpdate(features) {
      if (!features["new-access-control"].enabled)
        this.$router.replace("/dashboard/access-control").catch((failure) => {
          // navigation might be aborted by "discard changes" confirmation alert
          if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
            // revert feature to enabled to keep current UI state
            this.updateFeatureState(true);
          }
        });
    },
    ...mapActions("user", ["updateUsers", "fetchUsers"]),
    ...mapActions("role", ["fetchRoles"]),
    ...mapActions("processArea", ["fetchProcessAreas"])
  },
  mounted() {
    this.fetchResources();
    this.hierarchy = this.groupId
      ? "group"
      : this.processAreaId
      ? "process-area"
      : localStorage.getItem("access_control_hierarchy") ?? "group";
    this.$featureToggle.addUpdateListener(this.handleFeatureUpdate);
  },
  beforeDestroy() {
    this.$featureToggle.removeUpdateListener(this.handleFeatureUpdate);
  },
  activated() {
    if (!this.transitionEnabled) {
      this.resetState().then(() => (this.transitionEnabled = true));
    }
    this.$refs.selectedEntityEl.addEventListener(
      whichTransitionEvent(),
      this.restoreUsersListingTitle.bind(this)
    );
  },
  deactivated() {
    this.transitionEnabled = false;
  }
};
</script>

<style lang="scss" scoped>
.privileges-display::v-deep {
  .card,
  .card .box-header {
    transition-property: color, background, border-color;
    transition-duration: 0.3s;
  }
}

.cards-container {
  // parent height - back button height
  height: calc(100% - 34px);
  position: relative;

  @media (max-width: 767px) {
    height: 100%;
  }
}

.entity-search {
  max-width: 100%;
  min-width: 180px;
}

.info-text {
  font-size: 1.7rem;
  color: #555;
  position: absolute;
  top: 40%;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  z-index: 0;

  .glyphicon {
    font-size: 3.8rem;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition-property: transform, opacity;
}

.slide-fade-enter-active {
  transition-duration: 300ms;
}

.slide-fade-leave-active {
  transition-duration: 200ms;
}

.slide-fade-enter {
  transform: translateY(30%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: scale(0.7);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 150ms;
}

.slide-up-leave-to,
.slide-up-enter {
  transform: translateY(45px);
}

.section {
  position: relative;
  transition: all 0.3s;
  top: 0;

  &.section-move {
    transition: transform 0.5s;
  }

  &.section-leave-active,
  &.section-hide-leave-active,
  &.section-hide-enter-active {
    transition: all 0.3s;
    position: absolute;
  }

  &.section-hide-leave-active,
  &.section-hide-enter-active {
    transition: all 0.3s;
  }

  &.section-hide-leave,
  &.section-hide-enter-to {
    top: 0;
    opacity: 1;
  }

  &.section-hide-leave-to,
  &.section-hide-enter {
    top: 21px;
    opacity: 0;
  }
}

.hierarchy-entity-card,
.users-card.add-user {
  transition: all 0.3s;

  &.section-leave,
  &.section-enter-to {
    left: 555px;
    opacity: 1;

    @media (max-width: 1199px) {
      left: 455px;
    }

    @media (max-width: 991px) {
      inset-inline: 0;
      min-width: 526px;
    }
  }

  &.section-enter,
  &.section-leave-to {
    @media (max-width: 991px) {
      left: revert;
      right: -540px;
    }
  }

  &.section-hide-leave-active,
  &.section-hide-enter-active {
    left: 555px;

    @media (max-width: 1199px) {
      left: 455px;
    }
  }
}

.card-moving {
  transition: all 0.5s !important;
}

.hierarchy-entity-card,
.users-card.add-user.card-moving {
  &.section-leave-to,
  &.section-enter {
    left: 1080px;
    opacity: 0;
  }
}

.users-card.add-user,
.privileges-display:is(.was-editing, .editing)::v-deep
  .hierarchy-entity-card.add-entity {
  &.section-leave-to,
  &.section-enter {
    left: 0;
    opacity: 0;
  }
}

.privileges-display:is(.adding, .adding-hierarchy-entity)::v-deep
  .users-card.add-user {
  z-index: 3;
}

.privileges-display.adding::v-deep .users-card.add-user {
  &.section-leave-active,
  &.section-enter-active {
    z-index: 1;
  }
}

.privileges-display.done.was-adding-hierarchy-entity
  .users-card.add-user.section-hide-leave-active {
  left: 0;
}

.hierarchy-entity-card,
.add-user {
  &.section-enter-active {
    position: absolute;
  }
}

.hierarchy-list,
.users-card:not(.add-user) {
  &.section-leave-to,
  &.section-enter {
    top: 21px;
    opacity: 0;
  }
}

.users-card,
.hierarchy-entity-card {
  height: 100%;
}

.hierarchy-entity-card.add-user::v-deep .card .box-footer {
  .back {
    flex: 1;
  }
}

.users-card,
.hierarchy-entity-card {
  ::v-deep .card {
    max-width: 496px;
    margin-inline: auto;
    height: 100%;
    box-shadow: 0 0 5px 1px #1f1f1f69, 0px 12px 24px -1px #a8a8a82e;
  }
}

.users-card::v-deep {
  .card.v-tour__target--highlighted,
  .add .btn.btn-success.v-tour__target--highlighted {
    pointer-events: none;
  }
}

.privileges-display::v-deep {
  &:not(:is(.adding-hierarchy-entity, .adding)) {
    .users-card:not(.add-user) {
      &.section-leave-active,
      &.section-enter-active {
        position: absolute;
        left: 525px;

        @media (max-width: 1199px) {
          left: 455px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .users-card.users-card:not(.add-user) {
      &.section-leave-active,
      &.section-enter-active {
        left: 0;
      }
    }

    &.entity-selected {
      .users-card:not(.add-user) {
        &.section-leave-to,
        &.section-enter {
          top: 0;
          left: -102%;
        }
      }
    }

    &:not(.entity-selected) {
      .users-card:not(.add-user) {
        &.section-leave-to,
        &.section-enter {
          top: 0;
        }
      }
    }
  }
}

.users-card:not(.add-user) {
  z-index: 2;

  @media (max-width: 991px) {
    position: absolute;
    width: 100%;
  }

  ::v-deep .box-footer {
    display: flex;

    .add {
      text-align: center;
    }

    .back,
    .add,
    .spacing {
      flex: 1;
    }
  }
}

.hierarchy-list {
  max-width: 525px;

  @media (max-width: 991px) {
    margin: 0 auto;

    &.section-leave-active {
      inset-inline: 0;
    }
  }

  &::v-deep .list-group {
    max-height: 55vh;
  }
}

.hierarchy::v-deep {
  position: absolute;
  margin-top: 3px;
  left: 18.3%;
  z-index: 1;

  @media (max-width: 1199px) {
    position: relative;
    left: 0;
    margin-top: 0;
    margin-inline: 15px;

    &.visible-lg-flex {
      display: none;
    }
  }

  @media (max-width: 991px) {
    width: max-content;
    margin-inline: auto;
  }
}

.flex-spaced {
  display: flex;
  justify-content: space-between;
}

.selected-entity {
  position: absolute;
  z-index: 4;
  top: 92px;
  left: 84px;
  font-size: 1.6rem;
  transition: all 300ms;
  visibility: hidden;
}

.privileges-display.adding-hierarchy-entity {
  .selected-entity {
    visibility: visible;
    top: 51px;
    left: 45px;
    background: #00a65a;
    color: white;
    font-size: 1.5rem;
    padding: 0.4rem 0.6rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 6px 1px rgba(105, 105, 105, 0.3);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .selected-entity {
      top: 86px;
      left: 112px;
    }
  }

  @media (max-width: 767px) {
    & {
      padding-top: 2.5rem;
    }

    .selected-entity {
      top: 84px;
      left: 0;
      right: 0;
      max-width: max-content;
      margin: 0 auto;
      border-bottom-right-radius: 0;
    }
  }

  @media (max-width: 591px) {
    .selected-entity {
      top: 91px;
    }
  }

  @media (max-width: 476px) {
    .selected-entity {
      top: 127px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .selected-entity {
    left: 70px;
  }

  .privileges-display.adding-hierarchy-entity .selected-entity {
    left: 30px;
  }
}

@media (max-width: 991px) {
  .selected-entity {
    visibility: hidden;
  }

  .privileges-display:not(.adding-hierarchy-entity) .selected-entity {
    z-index: 0;
    top: 128px;
    left: 112px;
    background: #00a65a;
    color: white;
    font-size: 1.5rem;
    padding: 0.4rem 0.6rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 6px 1px rgba(105, 105, 105, 0.3);
  }
}
</style>
<style lang="scss">
.v-step.privileges-feature-question,
.privileges-display .v-step {
  color: #333;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  &.v-step--sticky {
    box-shadow: 0 5px 10px 9999px rgba(0, 0, 0, 0.2);
  }

  .v-step__header {
    font-size: 1.6rem;
    background-color: #f5f5f5;
  }

  .btn {
    padding-block: 3px;
    margin-inline: 0.5rem;
  }

  .v-step__arrow--dark:before {
    background-color: #f5f5f5;
  }
}
</style>
<style>
.skin-dark .hierarchy-entity-card h3 {
  color: #fff;
}
</style>
