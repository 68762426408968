<template>
  <div class="list">
    <h4 class="title">{{ title }}</h4>
    <ListFilter
      class="entity-search"
      :list="list"
      :fields="searchFields"
      :customFilter="searchFilter"
      @filter="setFilteredList"
      :flatButton="true"
      :placeholder="placeholder || $t('search') + ' ' + $tc(term).toLowerCase()"
      :q="query"
      :useQuery="useQuery"
    />
    <ListGroup :linked="true" :stripped="true" v-if="filteredList.length">
      <ListGroupItem
        v-for="item in filteredList"
        :key="item.id"
        :active="selectedItem == item"
        @click="selectItem($event.target, item)"
        :title="item[itemTitle]"
        data-toggle="tooltip"
        >{{ item[itemLabel] }}</ListGroupItem
      >
    </ListGroup>
    <span class="no-items" v-else>
      {{ $tc("no_item_found", gender, { item: $tc(term).toLowerCase() }) }}
    </span>
  </div>
</template>

<script>
import ListFilter from "@/components/widgets/list-filter";
import { ListGroup, ListGroupItem } from "@/components/base/list-group";
import MixinLocaleSort from "@/project/mixin-locale-sort";

export default {
  name: "FilterableList",
  mixins: [MixinLocaleSort],
  components: {
    ListGroup,
    ListGroupItem,
    ListFilter
  },
  props: {
    selected: {
      type: [Number, Object],
      default: null
    },
    title: {
      type: String,
      default() {
        return this.$tc("group", 2);
      }
    },
    placeholder: {
      type: String,
      default: ""
    },
    term: {
      type: String,
      default: "group"
    },
    gender: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      required: true
    },
    itemTitle: {
      type: String,
      default: "description"
    },
    itemLabel: {
      type: String,
      default: "name"
    },
    searchFields: {
      type: String,
      default: "name,description"
    },
    searchFilter: {
      type: Function,
      default: null
    },
    query: {
      type: String,
      default: ""
    },
    useQuery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filteredList: [],
      selectedItem: null
    };
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(item) {
        if (typeof item == "object") {
          this.selectedItem = item;
        } else if (item) {
          this.selectedItem = this.list.find((g) => g.id == item) ?? null;
        }
      }
    }
  },
  methods: {
    selectItem(el, item) {
      this.selectedItem = this.selectedItem != item ? item : null;
      this.$emit("select", this.selectedItem);
      $(el).tooltip("hide");
    },
    activateTooltip() {
      $(this.$el)
        .find("[data-toggle=tooltip]")
        .tooltip({
          delay: { show: 500, hide: 0 },
          placement: "auto top",
          container: "body"
        });
    },
    setFilteredList(list) {
      this.filteredList = this.localeSort(list, (g) => g.name);
    }
  },
  updated() {
    this.activateTooltip();
  },
  mounted() {
    this.activateTooltip();
  },
  beforeDestroy() {
    $(this.$el)
      .find("[data-toggle=tooltip]")
      .tooltip("destroy");
  }
};
</script>

<style lang="scss">
.no-items {
  font-size: 1.2em;
  text-align: center;
  color: hsl(0, 0%, 35%);
  width: 100%;
  display: block;
  font-weight: 500;
  padding: 0.5em 1em 0.5em 0.1em;
  margin-top: 1em;
}

.list {
  position: relative;

  .list-group {
    overflow-y: auto;

    .list-group-item {
      border-radius: 0;
      cursor: pointer;

      &:not(.active) {
        color: #31708f;
      }
    }
  }
}

.skin-dark .list {
  .list-group {
    .list-group-item {
      &:not(.active) {
        color: var(--skin-dark-light);
      }
    }
  }
}
</style>
